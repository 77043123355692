* {
  box-sizing: border-box;
}

/* Create two equal columns that floats next to each other */
.columnVital {
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.rowVital:after {
  content: "";
  display: table;
  clear: both;
}
